import React from 'react'

// Components
import SEO from '../components/seo'

// Style
import style from './index.module.css'

import TableOfContents from '../components/TableOfContents'

import HillsA from '../assets/frames/hills-a.svg'
import HillsB from '../assets/frames/hills-b.svg'
import HillsC from '../assets/frames/hills-c.svg'
import HillsD from '../assets/frames/hills-d.svg'
import HillsE from '../assets/frames/hills-e.svg'
import Oil from '../assets/frames/oil.svg'
import Sun from '../assets/frames/sun.svg'

const IndexPage = ({
	location
}: {
	location: {
		href: string
		pathname: string
	}
}) => (
	<section className={ style.Home }>
		<SEO title='Home' url={ location.href } />
		<figure className={ style.PhotoWall }>
			<Sun className={ style.Sun } />
			<HillsE className={ style.HillsE } />
			<Oil className={ style.Oil } />
			<HillsD className={ style.HillsD } />
			<HillsC className={ style.HillsC } />
			<HillsB className={ style.HillsB } />
			<HillsA className={ style.HillsA } />
		</figure>
		<header className={ style.Header }>
			<hgroup>
				<h2>A&nbsp;memoir&nbsp;by<br />Michael&nbsp;Blankenburg</h2>
				<h1>A<br />Long-Haired<br />Handsome<br />Jesus</h1>
			</hgroup>
			<div className={ style.Enter } />
		</header>
		<div className={ style.TOC }>
			<h3>Table of Contents</h3>
			<TableOfContents location={ location } />
		</div>
	</section>
)

export default IndexPage
